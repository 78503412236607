import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config/config';

@Injectable()
export class ConferenceProvider {

 config: Config = new Config();

  constructor(private http:HttpClient) { }

  start(idUsuario,serial,clientCode) {   

    let body = {
      "userId": idUsuario,
      "serial": serial,
      "shamanClientCode": clientCode
    };

    return this.http.post(this.config.PATH_REST_API + 'Conference/New',body);
  }

  finish(idConference) {   
    return this.http.get(this.config.PATH_REST_API + 'Conference/finish/'+ idConference);
  }

  rate(conferenceId,rating,comment){

    let rate = {
       conferenceId: conferenceId, 
       rating: rating, 
       comment: comment 
    };
    return this.http.post(this.config.PATH_REST_API + 'Conference/SendRating', rate);
  }

  getApizeeKey() {   
    
    return this.http.get(this.config.PATH_REST_API + "conference/GetKeyApiZee/1");

  }
  
  sendRecordedConference(data){
    return this.http.post(this.config.PATH_REST_API + 'Conference/SendConferenceRecordedFile', data);
  }

  sendFileInChat(conferenceId,name,file)
  {
    let body = {
      conferenceId: conferenceId, 
      name: name, 
      file: file 
   };
   
   return this.http.post(this.config.PATH_REST_API + 'Conference/SendFileInChat', body);
  }

  getWaitTime(idConference) {   
    return this.http.get(this.config.PATH_REST_API + "Conference/GetWaitTime/" + idConference);
  }

  existConference(idUsuario,serial){   
    
    return this.http.get(this.config.PATH_REST_API + 'Conference/GetScheduledConference/' + idUsuario + '/' + serial);

  }

  /*cancel(conferenceId){   
    let url = this.config.PATH_REST_API + 'Conference/CancelConference/' + conferenceId + '/' + encodeURI('Cita cancelada por el usuario');
    return this.http.get(url);
  }*/
  cancel(idConference) {   
    return this.http.get(this.config.PATH_REST_API + 'Conference/CancelConference/' + idConference + '/' + encodeURI('Cita cancelada por el usuario'));
  }

  getSpecialtyList(serial,userId,isScheduled){
    return this.http.get(this.config.PATH_REST_API + 'conference/GetConferenceSetting/' + serial + '/' + userId + '/' + isScheduled);
  }

  getDailyFrequency(serial,specialistId) {   

    let url = this.config.PATH_REST_API + 'List/GetDailyFrequency/' + serial;

    if (specialistId) {
      url = url + '/' + specialistId;
    }
    
    return this.http.get(url);   

  }

  getHourlyFrequencyByDate(serial,date,specialistId) {   

    let url = this.config.PATH_REST_API + 'List/GetHourlyFrequencyByDate/' + serial + '/' + date;

    if (specialistId) {
      url = url + '/' + specialistId;
    }
    
    return this.http.get(url);   
    
  }

  new(idUsuario, serial, encuestaContestadaID ,date = null, hourlyFrequencyId = null, specialistId = null,isChatOnly = false) {   
    
    let body = {"userId": idUsuario,
    "serial": serial,
    "encuestaContestadaID": encuestaContestadaID,
    "isChatOnly": isChatOnly,
    "date": date,
    "hourlyFrequencyId": hourlyFrequencyId,
    "specialistId": specialistId,
    "device": null,
    "copayId": null};

    return this.http.post(this.config.PATH_REST_API + 'Conference/New/',body);
  }

}