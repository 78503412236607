import { Injectable } from '@angular/core';
import { UsuarioAppProvider } from '../../providers/usuario-app/usuario-app';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService {

  constructor(private usuarioAppProvider:UsuarioAppProvider) { }

  public isAuthenticated(): boolean {
    return this.usuarioAppProvider.getUsuario() != undefined;
}

}
