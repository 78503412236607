import { Injectable } from '@angular/core';
import { UsuarioProvider } from '../usuario/usuario';
import { Parameters } from "../../config/parameters";
@Injectable()
export class UsuarioAppProvider {

  mensajeNuevo = false;
  fotos = [];
  usuario ;// {UnreadNotifications : 0};
  id_device = "";
  serialCompany = "";
  parameters: Parameters = new Parameters();
  
  constructor(
    private usuarioProvider: UsuarioProvider
    ) {

    let data = localStorage.getItem('usuario');

    if(data && data != "undefined"){
      this.usuario = JSON.parse(data);
    }
    // this.storage.get('usuario').then((val) => {
    //   if (val) {
    //     this.usuario = JSON.parse(val);
    //   }
    // });

  }

  updateClientDescription(){
      //Si tiene ClientCode obtenemos su descripción para mostrar en el header
      let clientCode = localStorage.getItem('clientCode');
      if(clientCode)
      {
        this.usuarioProvider.getTotemDescription(this.parameters.SERIAL,clientCode).subscribe(
          (rta: any) => {          
            this.usuario.totemDescription = rta.RazonSocial ? rta.RazonSocial : "";
            localStorage.setItem('usuario', JSON.stringify(this.usuario));
          },
          (err) => {
            console.error("Error al validar clientCode: ",err);
          },
          () => {}
        );
      }
  }

  getUsuarioCallBack(fn:any) {

    let data = localStorage.getItem('usuario');
    if(data && data != "undefined"){
      fn(JSON.parse(data));
    }
    else  
      fn()
    // this.storage.get('usuario').then((val) => {
    //   if (val) {
    //     fn(JSON.parse(val));
    //   }
    // });

  }

  getUsuario() {

    return this.usuario;

  }

  setUsuario(usuario) {

    localStorage.setItem('usuario', JSON.stringify(usuario));
    this.usuario = usuario;

    this.updateClientDescription();

  }

  setUsuarioOrder(orderId){
    this.usuario.orderBO = {OrderId : orderId};
    this.setUsuario(this.usuario);
  }
}
