export class Parameters{
    NOMBRE = "Doctor en Red";
    SERIAL = '2385942806';
    SHAMAN_CLIENT_CODE = '';
    ONESIGNAL_APPID = '8aee4e82-7f6d-4ee7-92f4-8ae5f8cbf040';
    ONESIGNAL_GOOGLEPROYECT = '761522745963';
    MASCARA = true;
    DOC = 'Documento';
    CUIT = 'Cuit';
    PASAPORTE = 'Pasaporte';
    PAIS = 'AR';
    VIDEOCALL_DESCRIPCION = 'Médico on-line';
    DOCTOR_DESCRIPCION = "Visita médica";
    DOCTOR_CALLME_DESCRIPCION = "Quiero que un médico me llame";
    VIDEOCALL_SCHEDULED_DESCRIPCION = 'Programar Médico on-line';
    VIDEOCALL_SCHEDULED_WARNING = "Recuerde estar atento al día y horario seleccionado";
    LOGIN_TYPE = 1;
    LOGIN_LEYEND = true;
    IMAGE_BACKGROUND_HOME=false;
    VALIDA_CLIENT_CODE=false;HEADER_DARK=true;    
}
