import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../../config/config';

@Injectable()
export class UsuarioProvider {

  config: Config = new Config();
  
  pantallaClaveIn:boolean;

  constructor(public http: HttpClient) {

  }

  login1 = async(dni, cuit, passport, serial_key, device_id, clave, xShamanCodeClient) => {
    try {

      let body = {
        dni: dni,
        cuit: cuit,
        clave: clave,
        passport: passport,
        serial_key: serial_key,
        device_id: device_id,
        shaman_client_code: xShamanCodeClient
      }

        var url = this.config.PATH_REST_API + 'Login';

        const respu = await fetch(url,{
                method: 'POST', // or 'PUT'
                body: JSON.stringify(body), // data can be `string` or {object}!
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }  
            });

            let data= await respu.json();
            return data;

    } catch (error) {
        alert(error);
    }

}

  login(dni, cuit, passport, serial_key, device_id, clave, xShamanCodeClient) {

      let body = {
        dni: dni,
        cuit: cuit,
        clave: clave,
        passport: passport,
        serial_key: serial_key,
        device_id: device_id,
        shaman_client_code: xShamanCodeClient,
        codigoProducto: 350
      };

      return this.http.post(this.config.PATH_REST_API + 'Login', body);    
  }

  recordarClave(dni, cuit, passport, serial_key) {

    try {

      let body = {
        dni: dni,
        cuit: cuit,
        passport: passport,
        serial_key: serial_key
      };

      return this.http.post(this.config.PATH_REST_API + 'Login/RememberPass', body);

    }
    catch (e) {
    
      throw e;
    }
  }

  enviarChat(idUsuarioEmisor, idUsuarioReceptor, mensaje) {

    let body = {
      idUsuarioEmisor,
      idUsuarioReceptor,
      mensaje
    };

    return this.http.post(this.config.PATH_REST_API + 'chat', body);
  }

  actualizarDatos(userBO) {
    let body = {
      usuario: userBO
    };

    return this.http.post(this.config.PATH_REST_API + 'Login/UpdateUser', body);
  }

  logout(user_id:number, device_id:string)
  {
    let body = {
      user_id: user_id,
      device_id: device_id
    };

    return this.http.post(this.config.PATH_REST_API + 'Login/Logout', body);
  }

  getTerminosYCondiciones(serial: string){
    return this.http.get(this.config.PATH_REST_API + `Login/GetTerms/${serial}` );
  };

  aceptarTerminosYCondiciones(userId: number){
    return this.http.get(this.config.PATH_REST_API + `Login/AcceptTerms/${userId}` );
  };

  getDoctorViewModel(doctorId: number){
    return this.http.get(this.config.PATH_REST_API + `Login/GetDoctorViewModelFromConference/${doctorId}` );
  };

  checkClientCode(serial,clientCode) {

    return this.http.get(this.config.PATH_REST_API + `Login/ValidateShamanClient/${serial}/${clientCode}`);
  }

  getTotemDescription(serial,clientCode) {
    return this.http.get(this.config.PATH_REST_API + `Login/GetShamanCliente/${serial}/${clientCode}`);
  }
}
