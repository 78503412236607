import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './contenedor/partials/layout/splash-screen/splash-screen.module';

import { UsuarioProvider } from '../providers/usuario/usuario';
import { UsuarioAppProvider } from '../providers/usuario-app/usuario-app';
import { TomarFotoComponent } from './components/tomar-foto/tomar-foto.component';
import { ConferenceProvider } from '../providers/conference/conference';
import { PedidoProvider} from '../providers/pedido/pedido';
import { EncuestaProvider } from '../providers/encuesta/encuesta';
import { ListProvider } from '../providers/list/list'
/* import { VideoCalificacionComponent } from './components/video-calificacion/video-calificacion.component'; */



import {MatDialogModule} from '@angular/material/dialog';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    // #fake-start#
    // environment.isMockEnabled
    //   ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
    //     passThruUnknownUrl: true,
    //     dataEncapsulation: false,
    //   })
    //   : [],
    // #fake-end#
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgbModule
 ,MatDialogModule
     
  ],
  providers: [
    UsuarioProvider,
    UsuarioAppProvider,
    ConferenceProvider,
    EncuestaProvider,
    PedidoProvider,
    ListProvider,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ]
  ,bootstrap:[AppComponent]
 
})
export class AppModule { }
