import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../config/config';

@Injectable()
export class PedidoProvider {

 config: Config = new Config();

  constructor(private http:HttpClient) { }

  get(idOrder) {
   
    return this.http.get(this.config.PATH_REST_API + 'Order/GetById/'+ idOrder);

  }

  post(order) {
   
    return this.http.post(this.config.PATH_REST_API + 'Order/Insert',order);

  }

  cancelPedido(orderId){
    return this.http.get(this.config.PATH_REST_API + 'Order/CancelByOrderId/'+ orderId + '/cancelado_por_el_usuario') ;
  }

}