import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from '../../config/config';
import { Parameters } from 'src/config/parameters';
import { CodeDescriptionListableNumber} from '../../app/models/CodeDescriptionListableNumber';
@Injectable()
export class ListProvider {

  config: Config = new Config();
  parameters: Parameters = new Parameters();
  ws = this.config.PATH_REST_API + 'List/';

  constructor(public http: HttpClient) { }

  getLocalidades(): Observable<CodeDescriptionListableNumber[]> {
    return this.http.get<CodeDescriptionListableNumber[]>(this.ws + `GetLocalidadesBySerial/${this.parameters.SERIAL}`);
  }
  
  getProvincias(): Observable<CodeDescriptionListableNumber[]> {
    return this.http.get<CodeDescriptionListableNumber[]>(this.ws + `GetProvinciasBySerial/${this.parameters.SERIAL}`);
  }

  getLocalidadesByProvincia(provinciaId): Observable<CodeDescriptionListableNumber[]> {
    return this.http.get<CodeDescriptionListableNumber[]>(this.ws + `GetLocalidadesBySerialAndProvince/${this.parameters.SERIAL}/${provinciaId}`);
  }

}
