import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../../config/config';

@Injectable()
export class EncuestaProvider {

  config: Config = new Config();
  ws = this.config.PATH_REST_API + 'Encuesta/';

  encuesta:any;

  constructor(public http: HttpClient) {
    
  }

  get(id,idUsuario,idCompania) {

    return this.http.get( this.ws + 'Get?id='+ id + '&idUsuario=' + idUsuario + '&idCia=' + idCompania);
  }

  getEncuestaContestadaList(idUsuario) {

    return this.http.get( this.ws + 'GetEncuestaContestadaList?idUsuario='+ idUsuario);
  }
  
  getEncuestaContestada(id){

    return this.http.get( this.ws + 'GetEncuestaContestada?id='+ id);
  }

  post(Encuesta:any, IdUsuario){

    let encuestaContestada = {
      Encuesta : Encuesta,
      UserId : IdUsuario 
    }

    //return this.http.post(this.ws + 'post', {"msj" : "asd"});
    return this.http.post(this.ws + 'Post', encuestaContestada);
  }

}
